import { Fragment } from "react";
import { AuthProvider } from "contexts/authContext";
import RoutesComponent from "routes";
import AlertComponent from "components/Alert";
import { NotificationsProvider } from "contexts/notificationsContext";
import { GlobalProvider } from "contexts/globalContext";

function App() {
	return (
		<NotificationsProvider>
			<AuthProvider>
				<GlobalProvider>
					<Fragment>
						<AlertComponent />
						<RoutesComponent />
					</Fragment>
				</GlobalProvider>
			</AuthProvider>
		</NotificationsProvider>
	);
}

export default App;
